@import "assets/styles/variables";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-title {
  font-family: Vollkorn;
  font-size: 2rem;
}

.form-signin {
  width: 100%;
  max-width: 360px;
  padding: 15px;
  margin: auto;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  color: $primary !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
}

.custom-menu-dropdown:hover {
  background-color: #fff !important;
  color: $primary !important;
}

.sigContainer {
  border: 1px solid #e8eaf0;
  box-sizing: border-box;
  border-radius: 4px;
}

.sigPad {
  width: "100%";
}

.card-header {
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

@import url("https://fonts.googleapis.com/css2?family=Vollkorn:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import "assets/styles/react-dataTable-component";

@import "~bootstrap/scss/bootstrap";
