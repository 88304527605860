/* FAQItem.css */
.faq-item {
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.faq-question {
  background-color: #f0f0f0;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-icon {
  font-size: 24px;
}

.faq-answer {
  background-color: #fff;
  padding: 15px;
  border-top: 1px solid #e0e0e0;
}
