body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.scrollable-container {
  overflow-x: auto;
  white-space: nowrap;
}

.header-row,
.content-row {
  display: flex;
  align-items: center;
  min-width: 1200px; /* Adjust based on your content width */
}

.datetoggler-text {
  width: 400px; /* Fixed width for the title container */
  text-align: center;
  font-weight: bold;
  font-size: 18px; /* Adjust as necessary */
  overflow: hidden; /* Ensures no text overflow */
}

.arrow-icon {
  cursor: pointer;
  font-size: 20px; /* Adjust size for better alignment */
}


.mb-5 {
  margin-bottom: 1.25rem; /* Adjust space below the header */
}


.column-actions,
.column-item-name,
.column-item-code,
.column-sort-order,
.column-app-days,
.column-dont-send,
.column-approver {
  flex: 0 0 auto; /* Ensures that columns do not shrink */
  padding: 0 10px; /* Provides padding between columns */
}

.column-actions {
  width: 100px;
}
.column-item-name {
  width: 200px;
}
.column-item-code {
  width: 300px;
}
.column-sort-order {
  width: 100px;
}
.column-app-days {
  width: 350px;
}
.column-dont-send {
  width: 200px;
}
.column-approver {
  width: 150px; /* Adjust as necessary for the number of approvers */
}

.text-light-grey {
  color: rgb(182 184 186)
}
