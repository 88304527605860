$primary: #296676;
$secondary: #94b2ba;

$white: #fff !default;

//tables
$headings-color: #5e5873 !default;
$body-bg: #fff !default;
$body-color: #6e6b7b !default;
$table-head-bg: #f3f2f7 !default;
$table-head-color: $headings-color !default;
$border-color: #e8eaf0 !default;

.fadeIn {
  animation: 0.1s fadeIn forwards;
}

.fadeOut {
  animation: 0.1s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}


